import React from 'react';
import { VscDebugDisconnect } from "react-icons/vsc";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error to a service if necessary
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{minHeight: '100vh', color: '#fff', display: "flex", gap: "10px", textAlign: "center", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
         <VscDebugDisconnect style={{fontSize: "60px"}}/>
         <h1>Something went wrong</h1>
         <p>{this.state.hasError}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
